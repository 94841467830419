<template>
  <div>
    <v-container class="px-5">
      <v-row>
        <v-col cols="12">
          <div class="text-overline font-weight-bold">Wallet Balance</div>
          <div class="primary--text"><sup class="text-h6">&#8358;</sup> <span v-if="wallet == ''" class="text-h3">********</span><span v-else class="text-h3">{{wallet | formatPrice}}</span></div>
          <div class="text-caption mt-2">Wallet ID: <span class="font-weight-bold secondary--text">{{user.accounts[1].account_number}}</span></div>
          <div class="mt-4">
            <v-btn small class="mr-4" color="#1B2845" dark depressed to="/dashboard/savings">Quick Save</v-btn>
            <fund-wallet @walletFunded="getWalletBalance()" />
          </div>
          <div class="mt-md-4 mt-sm-4">
            <v-btn
              v-if="!transaction_history"
              small
              color="#1B2845"
              dark
              depressed
              class="historyBtn"
              min-width="243px"
              @click="transaction_history = true"
            >Transaction History</v-btn>
            <v-btn
              @click="transaction_history = false"
              color="#1B2845"
              dark
              depressed
              flat
              min-width="243px"
              small
              class="historyBtn"
              v-if="transaction_history"
            >
              <v-icon class="mr-2">west</v-icon>
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="transaction_history">
        <transaction-history></transaction-history>
      </div>

      <div v-if="!transaction_history">
        <v-container fluid style="background: #E3F2FD" class="text-center grey lighten-5 pa-3 pa-sm-8 mt-10 rounded-lg">
          <v-row>
            <v-col>
              <v-card :class="airtime ? 'primary lighten-1 pa-4 pa-sm-8 rounded-lg white--text' : 'pa-4 pa-sm-8 rounded-lg primary--text'" elevation="5" @click="(airtime = true),(data = false),(electricity = false),(cable = false)">
                <div class="text-subtitle-1 font-weight-bold">Airtime</div>
              </v-card>
            </v-col>
            <v-col>
              <v-card :class="data ? 'primary lighten-1 pa-4 pa-sm-8 rounded-lg white--text' : 'pa-4 pa-sm-8 rounded-lg primary--text'" @click="(airtime = false),(data = true),(electricity = false),(cable = false)" elevation="5">
                <div class="text-subtitle-1 font-weight-bold">Data</div>
              </v-card>
            </v-col>
            <v-col>
              <v-card :class="electricity ? 'primary lighten-1 pa-4 pa-sm-8 rounded-lg white--text' : 'pa-4 pa-sm-8 rounded-lg primary--text'" @click="(airtime = false),(data = false), (electricity = true),(cable = false)" elevation="5">
                <div class="text-subtitle-1 font-weight-bold">Electricity</div>
              </v-card>
            </v-col>
            <v-col>
              <v-card :class="cable ? 'primary lighten-1 pa-4 pa-sm-8 rounded-lg white--text' : 'pa-4 pa-sm-8 rounded-lg primary--text'" @click="(airtime = false),(data = false),(electricity = false),(cable = true)" elevation="5">
                <div class="text-subtitle-1 font-weight-bold">Cable</div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-row justify="center" align="center" class="my-5">
          <v-col cols="12" md="8">
            <v-card class="grey lighten-5 rounded-lg pa-5 pt-10 pa-md-12" elevation="0" v-if="airtime">
              <div class="primary--text text-h6 font-weight-medium">Buy Airtime</div>
              <v-form class="mt-10" lazy-validation v-model="valid" ref="airtime">
                <v-select
                  class="mb-0"
                  placeholder="Select A Network Provider"
                  v-model="form.service_id"
                  :items="airtimes"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>

                <v-text-field
                  class="mb-0"
                  placeholder="Mobile Number (format: 08012394028)"
                  v-model="form.phone"
                  outlined solo
                  required
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Email"
                  v-model="form.email"
                  outlined solo
                  required
                  :rules="emailRules"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Amount"
                  v-model="form.amount"
                  outlined solo
                  required
                  oninput="if(this.value <= 0) this.value = '';"
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>
                <v-text-field
                  class="mb-0"
                  placeholder="PIN"
                  v-model="form.transaction_pin"
                  outlined solo
                  required
                  type="password"
                  :rules="[(v) => !!v || 'Please enter your pin']"
                ></v-text-field>
                <v-btn
                  class="primary"
                  x-large
                  :loading="loading"
                  block
                  @click="$refs.airtime.validate() ? airtime ? buyAirtime() : data ? buyData() : electricity ? buyElectricity() : cable ? buyCable() : null : null">Buy Airtime</v-btn
                >
              </v-form>
            </v-card>

            <v-card class="grey lighten-5 rounded-lg pa-5 pt-10 pa-md-12" elevation="0" v-if="data">
              <div class="primary--text text-h6 font-weight-medium">Buy Data</div>
              <v-form class="mt-10" lazy-validation v-model="valid" ref="data">
                <v-select
                  class="mb-0"
                  placeholder="Select your preferred Network"
                  v-model="form.service_id"
                  :items="networks"
                  item-text="text"
                  item-value="value"
                  @change="getDataBundles()"
                  outlined
                  required solo
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>
                <v-select
                  class="mb-0"
                  :loading="fetchBundles"
                  placeholder="Select a Data Plan"
                  v-model="form.data_bundles_id"
                  :items="dataBundles"
                  item-text="name"
                  @change="getAmount()"
                  item-value="id"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>
                <v-text-field
                  class="mb-0"
                  placeholder="Amount"
                  v-model="form.amount"
                  readonly
                  outlined solo
                  prefix="₦"
                  required
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Mobile Number (format: 08012394028)"
                  v-model="form.phone"
                  outlined
                  required solo
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Email"
                  v-model="form.email"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="PIN"
                  v-model="form.transaction_pin"
                  outlined solo
                  required
                  type="password"
                ></v-text-field>

                <v-btn
                  class="primary"
                  @click="$refs.data.validate() ? buyData() : null"
                  :loading="loading"
                  x-large
                  block
                  >Buy Data</v-btn
                >
              </v-form>
            </v-card>

            <v-card class="grey lighten-5 rounded-lg pa-5 pt-10 pa-md-12" elevation="0" v-if="electricity && cardinfo.status !== 1">
              <div class="primary--text text-h6 font-weight-medium">Buy Electricity</div>
              <v-form class="mt-10" lazy-validation v-model="valid" ref="power">
                <v-select
                  class="mb-0"
                  placeholder="Select A Power Provider"
                  v-model="form.service_id"
                  :items="powers"
                  @change="getStates()"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>

                <v-select
                  class="mb-0"
                  placeholder="Select State"
                  v-model="form.stateID"
                  :items="states"
                  outlined solo
                  @change="state()"
                  required
                  :loading="fetchStates"
                ></v-select>

                <v-select
                  class="mb-0"
                  placeholder="Select Disco"
                  v-model="form.disco"
                  outlined
                  solo
                  v-if="form.state == 'Lagos'"
                  required
                  :items="disco"
                ></v-select>

                <v-text-field
                  class="mb-0"
                  placeholder="Enter Meter Number"
                  v-model="form.meter_num"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-select
                  class="mb-0"
                  required
                  v-model="form.meterType"
                  outlined solo
                  placeholder="Meter type"
                  :items="[
                    { text: 'Prepaid', value: '1' },
                    { text: 'Post Paid', value: '2' }
                  ]"
                ></v-select>

                <v-text-field
                  class="mb-0"
                  placeholder="Email"
                  v-model="form.email"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Mobile Number"
                  v-model="form.phone"
                  outlined solo
                  required
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Amount"
                  v-model="form.amount"
                  outlined solo
                  required
                  type="number"
                  oninput="if(this.value <= 0) this.value = '';"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>
                <v-btn class="primary"
                  x-large
                  block
                  @click="$refs.power.validate() ? verifyPower() : null"
                  :loading="loading"
                  >Verify</v-btn>
              </v-form>
            </v-card>
            <v-card class="grey lighten-5 rounded-lg pa-5 pt-10 pa-md-12" elevation="0" v-if="electricity && cardinfo.status == 1">
              <div class="primary--text text-h6 font-weight-medium">Customer Details</div>
              <v-form class="mt-10" lazy-validation v-model="valid" ref="buypower">
                <v-text-field
                  class="mb-0"
                  label="Customer Name"
                  v-model="cardinfo.msg.name"
                  outlined solo
                  required
                  readonly
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  label="Customer Address"
                  v-model="cardinfo.msg.address"
                  outlined solo
                  required
                  readonly
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  label="Service Provider"
                  v-model="cardinfo.msg.util"
                  outlined solo
                  required
                  readonly
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  label="Amount To Pay"
                  v-model="cardinfo.amount"
                  outlined solo
                  required
                  readonly
                  oninput="if(this.value <= 0) this.value = '';"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  outlined
                  label="PIN"
                  placeholder="Enter PIN"
                  v-model="cardinfo.transaction_pin"
                  required
                  type="password"
                  :rules="[(p) => !!p || 'Please enter your pin to continue']"
                ></v-text-field>

                <v-btn class="primary"
                  x-large
                  block
                  @click="$refs.buypower.validate() ? buyPower() : null"
                  :loading="loading"
                  >Pay</v-btn>
              </v-form>
            </v-card>

            <v-card
              class="page-card customized-form mt-10 pa-4"
              :class="{ 'pa-12': $vuetify.breakpoint.smAndUp }"
              v-if="cable && cardinfo.customerName == null"
            >
              <h4 class="text-center">Subscribe for Cable</h4>
              <v-form class="mt-10" lazy-validation v-model="valid" ref="cable">
                <v-select
                  class="mb-0"
                  placeholder="Select Cable Type"
                  v-model="form.service_id"
                  :items="cables"
                  @change="getTvPlans(); provider();"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>

                <v-select
                  class="mb-0"
                  placeholder="Select a plan"
                  :loading="fetchBundles"
                  v-model="form.tv_plan_id"
                  :items="tvPlans"
                  @change="pkg()"
                  outlined solo
                  item-text="name"
                  item-value="id"
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-select>

                <v-text-field
                  class="mb-0"
                  placeholder="Enter Card Number"
                  v-model="form.smartcard_num"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Amount"
                  v-model="form.amount"
                  outlined solo
                  required
                  type="number"
                  oninput="if(this.value <= 0) this.value = '';"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Email"
                  v-model="form.email"
                  outlined solo
                  required
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>

                <v-text-field
                  class="mb-0"
                  placeholder="Mobile Number"
                  v-model="form.phone"
                  outlined solo
                  required
                  type="number"
                  :rules="[(v) => !!v || 'This field is required']"
                >
                </v-text-field>

                <v-btn
                  class="primary"
                  x-large
                  block
                  @click="$refs.cable.validate() ? verifyCable() : null"
                  :loading="loading"
                  >Verify</v-btn>
                <!-- <div class="text-center mt-2">
                  <small>Available Balance: ₦0.00</small>
                </div> -->
              </v-form>
            </v-card>
            <v-card
              class="page-card customized-form mt-10 pa-4"
              :class="{ 'pa-12': $vuetify.breakpoint.smAndUp }"
              v-if="cable && cardinfo.customerName !== null"
            >
              <h4 class="text-center">Customer Details</h4>
              <v-form lazy-validation ref="buycable">
                <v-text-field
                  readonly
                  outlined
                  label="Customer Name"
                  v-model="cardinfo.customerName"
                ></v-text-field>
                <v-text-field
                  readonly
                  outlined
                  label="Customer Number"
                  v-model="cardinfo.customerNumber"
                ></v-text-field>
                <v-text-field
                  label="Amount to pay"
                  readonly
                  outlined
                  v-model="cardinfo.amountToPay"
                  prefix="₦"
                  oninput="if(this.value <= 0) this.value = '';"
                ></v-text-field>
                <v-text-field
                  label="Transaction ID"
                  v-model="cardinfo.transactionID"
                  readonly
                  outlined
                ></v-text-field>
                <v-text-field
                  class="mb-0"
                  placeholder="PIN"
                  v-model="cardinfo.transaction_pin"
                  outlined
                  required
                  type="password"
                  :rules="[(p) => !!p || 'Please enter your pin to continue']"
                ></v-text-field>
                <v-btn
                  x-large
                  block
                  color="primary"
                  @click="$refs.buycable.validate() ? buyCable() : null"
                  :loading="loading"
                >Pay</v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>

        <!-- Power dialog -->
        <v-dialog
          v-model="tokenDialog"
          max-width="450"
        >
          <v-card
            class="pa-2 pb-5"
          >
            <v-card-title class="justify-center text-break">
              <img :src="require('../../../../public/favicon.png')" height="50" alt="">
              <span class="secondary--text" style="margin-left: -5px;">Tran</span>
              <span class="primary--text">Save</span>
            </v-card-title>
            <v-toolbar class="d-flex justify-center" flat>POWER PURCHASE DETAILS</v-toolbar>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Electricity Provider:</v-list-item-title>
                    <div class="primary--text float-right" style="font-size: 14px;">AEDC (Abuja)</div>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Meter Number:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">45024312243</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Email Address:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">meckyblaze@gmail.com</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Subscriber:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">Emmanuel Maduka</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Tran. Date:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">{{ new Date() | tx_date }}</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Status:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">Successful</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <div class="float-left" style="font-size: 12px;">Token:</div>
                    <span class="font-weight-bold text-break primary--text float-right" style="font-size: 14px;">{{ utility.token }}</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <div class="float-left" style="font-size: 12px;">Units:</div>
                    <div class="primary--text float-right text-break">{{ utility.units }}</div>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Amount:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">&#8358;{{ utility.amountPaid | formatPrice }}</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <div class="float-left" style="font-size: 12px;">Reference:</div>
                    <span class="font-weight-bold text-break float-right primary--text">{{ utility.transID }}</span>
                  </div>
                  <v-card-subtitle class="text-center dark--text">Generated By Transave</v-card-subtitle>
                  <v-text-field style="opacity: 0; position: absolute;" :value="utility.token" id="tokenID"></v-text-field>
                  <v-row class="mt-4">
                    <v-card
                      color="grey darken-1"
                      width="100%"
                      class="d-flex justify-space-around"
                    >
                    <v-btn
                      text
                      icon
                      color="white"
                      class="ml-2"
                      @click="(tokenDialog = false), (form = {})"
                    ><v-icon>mdi-close</v-icon></v-btn>
                    <v-btn
                      icon
                      text
                      color="white"
                      class=""
                      @click="copyToken('tokenID')"
                    ><v-icon>mdi-content-copy</v-icon></v-btn>
                    <!-- <v-btn
                      icon
                      color="white"
                      text
                      class=""
                    ><v-icon>mdi-share-variant</v-icon></v-btn> -->
                    <v-btn
                      icon
                      color="white"
                      text
                      class=""
                      @click="powerReceipt();"
                    ><v-icon>mdi-file-download-outline</v-icon></v-btn>
                    </v-card>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>

        <!-- Power receipt -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :pdf-quaility="2"
          :manual-pagination="false"
          pdf-content-width="100%"
          @hasStartedGeneration="hasGenerated($event)"
          :html-to-pdf-options="{
            filename: 'Transave Power Receipt',
            html2canvas: {
              scrollX: 0,
              scrollY: 0,
              scale: 5,
            },
            jsPDF: {
              unit: 'in',
              format: 'a6',
              orientation: 'portrait'
            },
          }"
          ref="powerReceipt"
        >
          <section slot="pdf-content">
            <v-card
              class="pa-2 pr-2 pb-5"
            >
              <v-card-title style="justify-content: center;">
                <img :src="require('../../../../public/favicon.png')" height="50" alt="">
                <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
                <span style="color: #171B70;">Save</span>
              </v-card-title>
              <v-toolbar style="display: flex; justify-content: center;" flat>POWER PURCHASE DETAILS</v-toolbar>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Electricity Provider:</v-list-item-title>
                      <div style="float: right; font-size: 14px; color: #171B70;">AEDC (Abuja)</div>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Meter Number:</v-list-item-title>
                      <span style="float: right; font-size: 14px; color: #171B70;">45024312243</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Email Address:</v-list-item-title>
                      <span style="float: right; font-size: 14px; color: #171B70;">{{ user.email }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Subscriber:</v-list-item-title>
                      <span style="float: right; font-size: 14px; color: #171B70;">{{ user.name }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                      <span style="float: right; font-size: 14px; color: #171B70;">{{ new Date() | tx_date }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Status:</v-list-item-title>
                      <span style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <div style="float: left; font-size: 12px;">Token:</div>
                      <span style="font-size: 14px; color: #171B70; float: right;">{{ utility.token }}</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <div style="float: left; font-size: 12px;">Units:</div>
                      <div style="font-size: 14px; color: #171B70; float: right;">{{ utility.units }}</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                      <span style="font-size: 14px; color: #171B70; float: right;">&#8358;{{ utility.amountPaid | formatPrice }}</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <div style="float: left; font-size: 12px;">Reference:</div>
                      <span style="font-size: 14px; color: #171B70; float: right;">{{ utility.transID }}</span>
                    </div>
                    <v-card-subtitle style="display: flex; justify-content: center;">Generated By Transave</v-card-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </section>
        </vue-html2pdf>

        <!-- Cable dialog -->
        <v-dialog
          v-model="cableDialog"
          max-width="450"
        >
          <v-card
            class="pa-8 pb-5"
          >
            <v-card-title class="justify-center text-break">
              <img :src="require('../../../../public/favicon.png')" height="50" alt="">
              <span class="secondary--text" style="margin-left: -5px;">Tran</span>
              <span class="primary--text">Save</span>
            </v-card-title>
            <v-toolbar class="d-flex justify-center" flat>CABLE TV PURCHASE DETAILS</v-toolbar>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Cable TV Provider:</v-list-item-title>
                    <div class="primary--text float-right" style="font-size: 14px;">DSTV</div>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Card Number:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">45024312243</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Email Address:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">{{ user.email }}</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Subscriber:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">{{ user.name }}</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Tran. Date:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">{{ new Date() | tx_date }}</span>
                  </div>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Status:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">Successful</span>
                  </div>
                  <v-divider></v-divider>
                  <div class="pa-2">
                    <v-list-item-title class="float-left" style="font-size: 12px;">Amount:</v-list-item-title>
                    <span class="primary--text float-right" style="font-size: 14px;">&#8358;{{ utility.amountPaid | formatPrice }}</span>
                  </div>
                  <v-divider></v-divider>
                  <v-card-subtitle class="text-center dark--text">Generated By Transave</v-card-subtitle>
                  <v-row class="mt-4">
                    <v-card
                      color="grey darken-1"
                      width="100%"
                      class="d-flex justify-space-around"
                    >
                    <v-btn
                      text
                      color="white"
                      class="ml-2"
                      @click="(cableDialog = false), (form = {})"
                    ><v-icon>mdi-close</v-icon></v-btn>
                    <v-btn
                      icon
                      text
                      color="white"
                      class=""
                      @click="copyToken('tokenID')"
                    ><v-icon>mdi-content-copy</v-icon></v-btn>
                    <!-- <v-btn
                      icon
                      color="white"
                      text
                      class=""
                    ><v-icon>mdi-share-variant</v-icon></v-btn> -->
                    <v-btn
                      icon
                      color="white"
                      text
                      class=""
                      @click="cableReceipt()"
                    ><v-icon>mdi-file-download-outline</v-icon></v-btn>
                    </v-card>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>

        <!-- Cable receipt for download -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :pdf-quaility="2"
          :manual-pagination="false"
          pdf-content-width="100%"
          @hasStartedGeneration="hasGenerated($event)"
          :html-to-pdf-options="{
            filename: 'Transave Cable Receipt',
            html2canvas: {
              scrollX: 0,
              scrollY: 0,
              scale: 5,
            },
            jsPDF: {
              unit: 'in',
              format: 'a6',
              orientation: 'portrait'
            },
          }"
          ref="cableReceipt"
        >
          <section slot="pdf-content">
            <v-card
              class="pa-2 pr-2 pb-5"
            >
              <v-card-title style="display: flex; justify-content: center;">
                <img :src="require('../../../../public/favicon.png')" height="50" alt="">
                <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
                <span style="color: #171B70;">Save</span>
              </v-card-title>
              <v-toolbar style="display: flex; justify-content: center;" flat>CABLE TV PURCHASE DETAILS</v-toolbar>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Cable TV Provider:</v-list-item-title>
                      <div style="float: right; color: #171B70; font-size: 14px;">DSTV</div>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Card Number:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">45024312243</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Email Address:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">{{ user.email }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Subscriber:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">{{ user.name }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">{{ new Date() | tx_date }}</span>
                    </div>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Status:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">Successful</span>
                    </div>
                    <v-divider></v-divider>
                    <div class="pa-2">
                      <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                      <span style="float: right; color: #171B70; font-size: 14px;">&#8358;{{ utility.amountPaid | formatPrice }}</span>
                    </div>
                    <v-divider></v-divider>
                    <v-card-subtitle style="text-align: center;">Generated By Transave</v-card-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </section>
        </vue-html2pdf>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FundWallet from '@/components/FundWallet.vue'
import TransactionHistory from '@/components/bills/transaction_history'
export default {
  layout: 'dashboard',
  transition: 'default',
  components: {
    FundWallet,
    TransactionHistory
  },

  data() {
    return {
      transaction_history: false,
      airtime: true,
      data: false,
      electricity: false,
      cable: false,
      valid: true,
      loading: false,
      airtimes: [
        { text: 'MTN', value: 1 },
        { text: 'Glo', value: 2 },
        { text: 'Airtel', value: 3 },
        { text: '9mobile', value: 4 },
      ],
      networks: [
        { text: 'MTN', value: 5 },
        { text: 'Etisalat', value: 6 },
        { text: 'Airtel', value: 7 },
        { text: 'Smile', value: 8 },
        { text: 'Spectranet', value: 9 },
      ],
      powers: [
        { text: 'AEDC', value: 10 },
        { text: 'AEDC Postpaid', value: 11 },
        { text: 'Ikeja Electric Bill Paymebt', value: 12 },
        { text: 'Ikeja Token Purchase', value: 13 },
        { text: 'Eko Prepaid', value: 14 },
        { text: 'Eko Postpaid', value: 15 },
        { text: 'Ibadan Disco Prepaid', value: 16 },
        { text: 'Kano Electricity Disco', value: 17 },
        { text: 'Kaduna Electricity Disco', value: 18 },
        { text: 'Kaduna Electricity Disco Postpaid', value: 19 },
        { text: 'PHeD Electricity', value: 20 },
      ],
      cables: [
        { text: 'DSTV', value: 21 },
        { text: 'GOTV', value: 22 },
        { text: 'StarTimes', value: 23 },
      ],
      form: {},
      dataBundles: [],
      tvPlans: [],
      fetchBundles: false,
      paystackkey: process.env.VUE_APP_ENV_PAYSTACK_PUBLIC_KEY,

      wallet: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      states: [],
      fetchStates: false,
      cardinfo: {
        customerName: null,
        token: null,
        status: null,
      },
      disco: [
        { text: 'Eko', value: '1' },
        { text: 'Ikeja', value: '2' }
      ],
      tokenDialog: false,
      cableDialog: false,
      utility: {
          amountPaid: 0,
      },
    }
  },

  created() {
    this.getWalletBalance()
    this.form.email = this.user.email
    this.form.phone = this.user.phone
    this.createReference()
  },

  methods: {
    getAmount() {
      this.form.amount = this.dataBundles.find(
        (x) => x.id === this.form.data_bundles_id
      ).amount
    },

    provider() {
      this.form.provider = this.cables.find(
        (x) => x.value === this.form.service_id
      ).text
    },

    pkg() {
      this.form.package = this.tvPlans.find(
        (x) => x.id === this.form.tv_plan_id
      ).name

      this.form.packageID = this.form.tv_plan_id
    },

    state() {
      this.form.state = this.states.find(
        (x) => x.value === this.form.stateID
      ).text
    },

    async getStates() {
      this.fetchStates = true
      try {
        const response = await this.$store.dispatch('bills/states')
        this.fetchStates = false
        response.forEach(state => {
          this.states.push({
            value: state.id,
            text: state.state
          })
        })
      } catch(error) {
        this.fetchStates = false
      }
    },

    clickPaystack() {
      document.getElementById('paystack').click()
    },

    createReference() {
      let text = '';
      let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      this.form.payment_ref = `TS_WEB_${text}`;
    },

    callback: function (response) {
      this.airtime
        ? this.buyAirtime()
        : this.data
        ? this.buyData()
        : this.electricity
        ? this.buyElectricity()
        : this.cable
        ? this.buyCable()
        : null;

      this.createReference();
    },

    close: function () {},

    async buyAirtime() {
      if (this.form.amount > this.withdrawal_limit) {
        this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (process.env.NODE_ENV !== 'production') {
        this.$toast.error('Invalid credentials!')
      }

      else if (this.form.amount < 10) {
        this.$toast.error('Minimum airtime amount is ₦10')
      }

      else {
        this.loading = true;
        try {
          const response = await this.$store.dispatch('bills/buyairtime', this.form);
          this.loading = false;
          if (response.success == false) return this.$toast.error(response.msg);
          this.$toast.success(response.msg);
          this.getWalletBalance();
          this.form = {};
        } catch (error) {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        }
      }
    },

    async buyData() {
      if (this.form.amount > this.withdrawal_limit) {
        this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (process.env.NODE_ENV !== 'production') {
        this.$toast.error('Invalid credentials!')
      }

      else if (this.form.amount < 10) {
        this.$toast.error('Minimum data amount is ₦100')
      }

      else {this.loading = true;
        try {
          let response = await this.$store.dispatch('bills/buydata', this.form);
          if (response.success == false) {
            this.$toast.error(response.msg);
          } else {
            this.$toast.success(response.msg);
            this.getWalletBalance();
          }
          this.form = {};
          this.loading = false;
        } catch(error) {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        }
      }
    },

    async getDataBundles() {
      this.fetchBundles = true;
      const data = {
        id: this.form.service_id,
      };
      try {
        let response = await this.$store.dispatch('bills/databundles', data);
        this.dataBundles = response;
        this.fetchBundles = false;
      } catch (error) {
        this.fetchBundles = false;
        this.$toast.error(error.response.message)
      }
    },

    async getTvPlans() {
      this.fetchBundles = true;
      const data = {
        id: this.form.service_id,
      };
      try {
        let response = await this.$store.dispatch('bills/tvplans', data);
        this.tvPlans = response;
        this.fetchBundles = false;
      } catch (error) {
        this.fetchBundles = false;
        this.$toast.error(error.response.data.message);
      }
    },

    async verifyCable() {
      this.loading = true;
      try {
        let response = await this.$store.dispatch('bills/verifycable', this.form);
        this.loading = false;
        console.log(response);
        if (response.status !== 1) {
          this.$toast.error(response.msg.msg.message);
        } else {
          this.cardinfo = response.msg;
        }
      }
      catch(err) {
        this.loading = false;
        this.$toast.error(err.response.data.message);
      }
    },

    async buyCable() {
      if (this.form.amount > this.withdrawal_limit) {
        this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (process.env.NODE_ENV !== 'production') {
        this.$toast.error('Invalid credentials!')
      }

      else {
        this.loading = true;
        const data = {
          user_id: this.user.id,
          token: this.cardinfo.token,
          transaction_pin: this.cardinfo.transaction_pin,
          amount_paid: this.cardinfo.amountToPay,
        };
        try {
          const response = await this.$store.dispatch('bills/buycable', data);
          this.loading = false;
          this.cardinfo = {};
          this.$refs.cable.reset();
          if (response.success == true) {
            this.$toast.success(response.msg);
          }
          if (response.success == false) {
            this.$toast.error(response.msg)
          }
          this.cardinfo.customerName = null
          this.getWalletBalance()
          console.log(response.success)
        } catch (error) {
          this.loading = false
          this.$toast.error(error.response.data.message)
        }
      }
    },

    async getWalletBalance() {
      const data = {
        user_id: this.user.id,
      };
      try {
        let response = await this.$store.dispatch("auth/wallet", data, { root: true })
          if (response == 0) {
            this.wallet = "0";
          } else {
            this.wallet = response;
          }
        } catch (error) {

        }
    },

    async verifyPower() {
      this.loading = true;
      let disco = '';
      this.form.state == 'Lagos' ? disco = this.form.disco : disco = 1;
      const data = {
        meterType: this.form.meterType,
        meter_num: this.form.meter_num,
        amount: this.form.amount,
        email: this.form.email,
        phone: this.form.phone,
        stateID: this.form.stateID,
        state: this.form.state,
        disco: disco,
      };
      try {
        const response = await this.$store.dispatch('bills/verifypower', data);
        this.loading = false;
        if (response.token == 0) {
          this.$toast.error(response.msg)
        }
        this.cardinfo = response
      }
      catch(err) {
        this.loading = false
        this.$toast.error(err.response.data.message)
      }
    },

    async buyPower() {
      if (this.form.amount > this.withdrawal_limit) {
        this.$toast.error('Your maximum transaction limit is ₦' + this.$options.filters.formatPrice(this.withdrawal_limit) + ', Update your KYC level to transact more.')
      }

      else if (process.env.NODE_ENV !== 'production') {
        this.$toast.error('Invalid credentials!')
      }

      else {
        this.loading = true;
        const data = {
          token: this.cardinfo.token,
          user_id: this.user.id,
          amount_paid: this.cardinfo.amount,
          pin: this.cardinfo.transaction_pin
        }
        try {
          const response = await this.$store.dispatch('bills/buypower', data);
          this.loading = false;
          if (response.success == true) {
            this.utility = response.msg;
            this.$toast.success('Transaction successful');
            this.cardinfo = {};
            this.$refs.power.reset();
            this.tokenDialog = true;
          }
          this.getWalletBalance()
        } catch (error) {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        }
      }
    },

    copyToken(id) {
      document.getElementById(id).select();
      document.execCommand('copy');
      this.$toast.success('Token copied to clipboard');
    },

    async powerReceipt() {
      try {
        this.tokenDialog = false;
        this.$toast.success('PDF downloading...');
        await this.$refs.powerReceipt.generatePdf();
      } catch (error) {
        this.$toast.error('Something went wrong, try again');
      }
    },

    async cableReceipt() {
      try {
        this.cableDialog = false;
        this.$toast.success('PDF downloading...');
        await this.$refs.cableReceipt.generatePdf();
      } catch (error) {
        this.$toast.error('Something went wrong, try again');
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
}
</script>
<style lang="scss" scoped>
  .page-card {
    background: #ffffff;
    border: 1px solid #fdeaea;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px;
    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      color: #171b70;
    }
  }
  small {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #353ccd;
  }

  @media(max-width: 599px) {
    .historyBtn {
      margin-top: 16px;
    }
  }
</style>
