<template>
  <div>
    <v-row class="grey lighten-5 mt-4">
      <v-col class="pa-0 pa-sm-2 pa-md-4">
        <v-card
          flat
          class="pa-0 pa-sm-2 pa-md-4"
        >
          <v-row
            flat
            class="primary--text text-h5"
          >
            <v-card-title
              class="cols-12 col-sm-6 col-md-6"
            >Airtime Transactions</v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              dense
              v-model="search"
              placeholder="Search Transactions..."
              class="mt-4 cols-12 col-sm-4 col-md-6"
              append-icon="search"
            ></v-text-field>
          </v-row>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="transactions"
            :loading="loadTransactions"
          >
            <template v-slot:[`item.numbers`]="{ index }">
              <div>{{ index + 1 }}</div>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
              <div>{{ item.date_created | date }}</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                @click="viewTransaction(item)"
                color="primary"
                small
              >View</v-btn>
            </template>
          </v-data-table>

        </v-card>
      </v-col>
    </v-row>

    <!-- Airtime dialog -->
    <v-dialog
      v-model="dialog"
      max-width="450"
    >
      <v-card
        class="pa-2 pb-5"
      >
        <v-card-title class="justify-center text-break">
          <img :src="require('../../../../public/favicon.png')" height="50" alt="">
          <span class="secondary--text" style="margin-left: -5px;">Tran</span>
          <span class="primary--text">Save</span>
        </v-card-title>
        <v-toolbar class="d-flex justify-center" flat>AIRTIME PURCHASE DETAILS</v-toolbar>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Provider:</v-list-item-title>
                <div class="primary--text float-right" style="font-size: 14px;">{{ viewItem.service.name }}</div>
              </div>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Phone Number:</v-list-item-title>
                <span class="primary--text float-right" style="font-size: 14px;">{{ viewItem.phone }}</span>
              </div>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Tran. Date:</v-list-item-title>
                <span class="primary--text float-right" style="font-size: 14px;">{{ viewItem.date_created | tx_date }}</span>
              </div>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Amount:</v-list-item-title>
                <span class="primary--text float-right" style="font-size: 14px;">&#8358;{{ viewItem.amount_paid | formatPrice }}</span>
              </div>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Payment Method:</v-list-item-title>
                <span class="primary--text float-right" style="font-size: 14px;">{{ viewItem.payment_method }}</span>
              </div>
              <v-divider></v-divider>
              <div class="pa-2">
                <v-list-item-title class="float-left" style="font-size: 12px;">Status:</v-list-item-title>
                <span class="primary--text float-right" style="font-size: 14px;">Successful</span>
              </div>
              <v-divider></v-divider>
              <v-card-subtitle class="text-center dark--text">Generated By Transave</v-card-subtitle>
              <v-text-field style="opacity: 0; position: absolute;" :value="viewItem.token" id="tokenID"></v-text-field>
              <v-row class="mt-4">
                <v-card
                  color="grey darken-1"
                  width="100%"
                  class="d-flex justify-space-around"
                >
                <v-btn
                  text
                  icon
                  color="white"
                  class="ml-2"
                  @click="closeDialog()"
                ><v-icon>mdi-close</v-icon></v-btn>
                <v-btn
                  icon
                  text
                  color="white"
                  class=""
                  @click="copyToken('tokenID')"
                ><v-icon>mdi-content-copy</v-icon></v-btn>
                <!-- <v-btn
                  icon
                  color="white"
                  text
                  class=""
                ><v-icon>mdi-share-variant</v-icon></v-btn> -->
                <v-btn
                  icon
                  color="white"
                  text
                  class=""
                  @click="airtimeReceipt();"
                ><v-icon>mdi-file-download-outline</v-icon></v-btn>
                </v-card>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- Airtime receipt -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quaility="2"
      :manual-pagination="false"
      pdf-content-width="100%"
      @hasStartedGeneration="hasGenerated($event)"
      :html-to-pdf-options="{
        filename: 'Transave Airtime Receipt',
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          scale: 5,
        },
        jsPDF: {
          unit: 'in',
          format: 'a6',
          orientation: 'portrait'
        },
      }"
      ref="airtimeReceipt"
    >
      <section slot="pdf-content">
        <v-card
          class="pa-2 pr-2 pb-5"
        >
          <v-card-title style="justify-content: center;">
            <img :src="require('../../../../public/favicon.png')" height="50" alt="">
            <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
            <span style="color: #171B70;">Save</span>
          </v-card-title>
          <v-toolbar style="display: flex; justify-content: center;" flat>AIRTIME PURCHASE DETAILS</v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Provider:</v-list-item-title>
                  <div style="float: right; font-size: 14px; color: #171B70;">{{ viewItem.service.name }}</div>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Phone Number:</v-list-item-title>
                  <span style="float: right; font-size: 14px; color: #171B70;">{{ viewItem.phone }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                  <span style="float: right; font-size: 14px; color: #171B70;">{{ viewItem.date_created | tx_date }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                  <span style="font-size: 14px; color: #171B70; float: right;">&#8358;{{ viewItem.amount_paid | formatPrice }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <div style="float: left; font-size: 12px;">Payment Method:</div>
                  <span style="font-size: 14px; color: #171B70; float: right;">{{ viewItem.payment_method }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Status:</v-list-item-title>
                  <span style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                </div>
                <v-divider></v-divider>
                <v-card-subtitle style="display: flex; justify-content: center;">Generated By Transave</v-card-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: "airtime_transactions",

    data() {
      return {
        headers: [
          {
            text: 'S/N',
            value: 'numbers',
            sortable: false,
            orderable: false,
          },
          { texf: 'Provider', value: 'service.name' },
          { text: 'Phone', value: 'phone' },
          { text: 'Amount', value: 'amount_paid' },
          { text: 'Payment Method', value: 'payment_method' },
          { text: 'Date', value: 'date_created' },
          { text: 'Action', value: 'action' },
        ],
        transactions: [],
        loadTransactions: false,
        search: '',
        viewItem: {
          service: { name: '' },
          phone: '',
          amount_paid: '',
          payment_method: '',
          date_created: '',
        },
        dialog: false,
      }
    },

    computed: {
      ...mapGetters('auth', [
        'user'
      ]),
    },

    created() {
      this.transaction_history();
    },

    methods: {
      ...mapActions('bills', [
        'airtime_transactions',
      ]),

      async transaction_history() {
        this.loadTransactions = true;
        try {
          let response = await this.airtime_transactions(this.user.id);
          this.loadTransactions = false;
          this.transactions = response.transactions;
        }
        catch (error) {
          this.loadTransactions = false;
          this.$toast.error(error.response.data.message);
        }
      },

      openDialog() {
        this.dialog = true;
      },

      closeDialog() {
        this.dialog = false;
      },

      viewTransaction(item) {
        this.openDialog()
        this.viewItem = Object.assign({}, item)
      },

      async airtimeReceipt() {
        this.closeDialog()
        try {
          await this.$refs.airtimeReceipt.generatePdf()
          this.tokenDialog = false
          this.$toast.success('Your download will start in a moment, please wait...')
        } catch (error) {
          this.$toast.error('Something went wrong, try again')
        }
      },
    }
  }
</script>

<style scoped>

</style>