<template>
  <v-container fluid class="pa-0 ma-0">
    <v-select
      class="cols-12 col-sm-5 col-md-5 pl-0"
      :items="options"
      v-model="selectedOption"
      @change="showHistory(selectedOption)"
    ></v-select>

    <airtime-transactions
      v-if="airtime_transactions"
    ></airtime-transactions>

    <data-transactions
      v-if="data_transactions"
    ></data-transactions>

    <power-transactions
      v-if="power_transactions"
    ></power-transactions>

    <tv-transactions
      v-if="tv_transactions"
    ></tv-transactions>

  </v-container>
</template>

<script>
  import PowerTransactions from '@/components/bills/transactions_history/power'
  import AirtimeTransactions from '@/components/bills/transactions_history/airtime'
  import DataTransactions from '@/components/bills/transactions_history/data'
  import TvTransactions from '@/components/bills/transactions_history/tv'
  export default {
    name: "transaction_history",

    components: {
      PowerTransactions,
      AirtimeTransactions,
      DataTransactions,
      TvTransactions
    },

    data() {
      return {
        airtime_transactions: true,
        data_transactions: false,
        power_transactions: false,
        tv_transactions: false,
        selectedOption: { text: 'Airtime', value: 'airtime_transactions' },
        options: [
          { text: 'Airtime', value: 'airtime_transactions' },
          { text: 'Data', value: 'data_transactions' },
          { text: 'Power', value: 'power_transactions' },
          { text: 'Cable', value: 'tv_transactions' },
        ],
      }
    },

    computed: {
      
    },

    methods: {
      showHistory(history) {
        switch (history) {
          case 'airtime_transactions':
            this.data_transactions    = false;
            this.power_transactions   = false;
            this.tv_transactions      = false;
            this.airtime_transactions = true; 
            break;
          case 'data_transactions':
            this.airtime_transactions = false;
            this.power_transactions   = false;
            this.tv_transactions      = false;
            this.data_transactions    = true;
            break;
          case 'power_transactions':
            this.data_transactions    = false;
            this.airtime_transactions = false;
            this.tv_transactions      = false;
            this.power_transactions   = true;
            break;
          case 'tv_transactions':
            this.data_transactions    = false;
            this.power_transactions   = false;
            this.airtime_transactions = false;
            this.tv_transactions      = true;
            break;
          default:
            this.data_transactions    = false;
            this.power_transactions   = false;
            this.tv_transactions      = false;
            this.airtime_transactions = true;
            break;
        }
      }
    }

  }
</script>

<style scoped>

</style>